table.table.is-table-component th {
  white-space: nowrap;
}
table.table.is-table-component th.sortable {
  cursor: pointer;
}
table.table.is-table-component th.is-narrow span:last-child {
  white-space: break-spaces;
}
table.table.is-table-component tr.clickable-row:not(.no-results) {
  cursor: pointer;
}
table.table.is-table-component tr.row-link td {
  padding: 0;
  position: relative;
}
table.table.is-table-component tr.row-link td > a {
  display: block;
  padding: 0.5em 0.75em;
  color: inherit;
}
table.table.is-table-component tr.row-link td > a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
table.table.is-table-component td, table.table.is-table-component th {
  vertical-align: middle;
}
table.table.is-table-component td.narrow, table.table.is-table-component th.narrow {
  width: 1px;
  white-space: nowrap;
}
table.table.is-table-component td .icon:only-child {
  width: 100%;
}
@media screen and (max-width: 1023px) {
table.table.is-table-component {
    display: block;
}
table.table.is-table-component thead, table.table.is-table-component tbody, table.table.is-table-component th, table.table.is-table-component td, table.table.is-table-component tr {
    display: block;
}
table.table.is-table-component thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
}
table.table.is-table-component tr {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-top: 4px solid #888;
}
table.table.is-table-component tr:not(:first-child) {
    margin-top: 1em;
}
table.table.is-table-component td {
    position: relative;
    padding-left: 50%;
}
table.table.is-table-component td:last-child {
    border-bottom: none;
}
table.table.is-table-component tbody tr:last-child td {
    border-bottom-width: 1px;
}
table.table.is-table-component tr.row-link > td > a, table.table.is-table-component tr:not(.row-link) > td:not([data-label=""]) > div {
    padding-top: 2.5em !important;
}
table.table.is-table-component td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    white-space: nowrap;
    padding-right: 6px;
    font-weight: bold;
    content: attr(data-label);
}
table.table.is-table-component td.is-narrow {
    white-space: initial !important;
    width: initial !important;
}
}