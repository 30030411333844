.button.is-auto-progress {
  /* TODO - handle size obtained from the group */
}
.button.is-auto-progress::before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: -1px;
  width: 0;
  background-color: rgba(0, 0, 0, 0.3);
  /* TODO - handle different colors of the button */
  opacity: 1;
  transition: opacity 2s;
  height: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.button.is-auto-progress.is-small::before {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.button.is-auto-progress.is-medium::before {
  height: 6px;
}
.button.is-auto-progress.is-large::before {
  height: 8px;
}
.button.is-auto-progress.has-progress-large::before {
  height: calc(100% + 2px);
  border-radius: 4px;
}
.button.is-auto-progress.has-progress-large.is-small::before {
  border-radius: 2px;
}
.button.is-auto-progress[data-progress="1"]::before {
  width: calc(1% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="2"]::before {
  width: calc(2% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="3"]::before {
  width: calc(3% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="4"]::before {
  width: calc(4% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="5"]::before {
  width: calc(5% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="6"]::before {
  width: calc(6% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="7"]::before {
  width: calc(7% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="8"]::before {
  width: calc(8% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="9"]::before {
  width: calc(9% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="10"]::before {
  width: calc(10% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="11"]::before {
  width: calc(11% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="12"]::before {
  width: calc(12% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="13"]::before {
  width: calc(13% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="14"]::before {
  width: calc(14% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="15"]::before {
  width: calc(15% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="16"]::before {
  width: calc(16% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="17"]::before {
  width: calc(17% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="18"]::before {
  width: calc(18% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="19"]::before {
  width: calc(19% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="20"]::before {
  width: calc(20% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="21"]::before {
  width: calc(21% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="22"]::before {
  width: calc(22% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="23"]::before {
  width: calc(23% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="24"]::before {
  width: calc(24% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="25"]::before {
  width: calc(25% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="26"]::before {
  width: calc(26% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="27"]::before {
  width: calc(27% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="28"]::before {
  width: calc(28% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="29"]::before {
  width: calc(29% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="30"]::before {
  width: calc(30% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="31"]::before {
  width: calc(31% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="32"]::before {
  width: calc(32% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="33"]::before {
  width: calc(33% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="34"]::before {
  width: calc(34% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="35"]::before {
  width: calc(35% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="36"]::before {
  width: calc(36% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="37"]::before {
  width: calc(37% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="38"]::before {
  width: calc(38% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="39"]::before {
  width: calc(39% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="40"]::before {
  width: calc(40% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="41"]::before {
  width: calc(41% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="42"]::before {
  width: calc(42% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="43"]::before {
  width: calc(43% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="44"]::before {
  width: calc(44% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="45"]::before {
  width: calc(45% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="46"]::before {
  width: calc(46% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="47"]::before {
  width: calc(47% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="48"]::before {
  width: calc(48% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="49"]::before {
  width: calc(49% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="50"]::before {
  width: calc(50% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="51"]::before {
  width: calc(51% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="52"]::before {
  width: calc(52% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="53"]::before {
  width: calc(53% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="54"]::before {
  width: calc(54% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="55"]::before {
  width: calc(55% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="56"]::before {
  width: calc(56% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="57"]::before {
  width: calc(57% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="58"]::before {
  width: calc(58% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="59"]::before {
  width: calc(59% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="60"]::before {
  width: calc(60% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="61"]::before {
  width: calc(61% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="62"]::before {
  width: calc(62% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="63"]::before {
  width: calc(63% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="64"]::before {
  width: calc(64% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="65"]::before {
  width: calc(65% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="66"]::before {
  width: calc(66% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="67"]::before {
  width: calc(67% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="68"]::before {
  width: calc(68% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="69"]::before {
  width: calc(69% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="70"]::before {
  width: calc(70% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="71"]::before {
  width: calc(71% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="72"]::before {
  width: calc(72% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="73"]::before {
  width: calc(73% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="74"]::before {
  width: calc(74% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="75"]::before {
  width: calc(75% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="76"]::before {
  width: calc(76% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="77"]::before {
  width: calc(77% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="78"]::before {
  width: calc(78% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="79"]::before {
  width: calc(79% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="80"]::before {
  width: calc(80% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="81"]::before {
  width: calc(81% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="82"]::before {
  width: calc(82% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="83"]::before {
  width: calc(83% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="84"]::before {
  width: calc(84% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="85"]::before {
  width: calc(85% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="86"]::before {
  width: calc(86% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="87"]::before {
  width: calc(87% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="88"]::before {
  width: calc(88% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="89"]::before {
  width: calc(89% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="90"]::before {
  width: calc(90% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="91"]::before {
  width: calc(91% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="92"]::before {
  width: calc(92% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="93"]::before {
  width: calc(93% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="94"]::before {
  width: calc(94% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="95"]::before {
  width: calc(95% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="96"]::before {
  width: calc(96% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="97"]::before {
  width: calc(97% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="98"]::before {
  width: calc(98% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="99"]::before {
  width: calc(99% + 2px);
  min-width: 4px;
}
.button.is-auto-progress[data-progress="100"]::before {
  width: calc(100% + 2px);
  min-width: 4px;
}
.button.is-auto-progress.has-auto-progress-finished::before {
  opacity: 0;
}