.is-drag-upload {
  position: relative;
  min-height: 180px;
}
.is-drag-upload .is-drag-helper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0.3;
  text-align: center;
  background-image: url(/static/dist/img/8931dea279e3867ed89f652f07352408.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 45px;
}
.is-drag-upload .is-drag-helper span {
  position: absolute;
  top: calc(50% + 35px);
  width: 100%;
  left: 0px;
  user-select: none;
}
.is-drag-upload .is-drag-helper span.is-drop {
  display: none;
}
.is-drag-upload.is-dragged {
  background-color: #effaf3;
}
.is-drag-upload.is-dragged .is-drag-helper {
  background-image: url(/static/dist/img/b6094586f80b226f48283105d04335c4.svg);
}
.is-drag-upload.is-dragged .is-drag-helper span.is-standard {
  display: none;
}
.is-drag-upload.is-dragged .is-drag-helper span.is-drop {
  display: block;
}