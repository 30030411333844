
.battery-indicator-wrapper {
    width: fit-content;
    position: relative;
}
.battery-indicator-wrapper .icon svg {
    z-index: 1;
}
.battery-indicator-wrapper .battery-indicator {
    position: absolute;
    left: 1px;
    top: 16px;
    height: 16px;
    /* width: 122%;
    background-color: blue; */
    z-index: 0;
}
