
.modal-content.bounceIn[data-v-705f785f]{
    animation-delay: 200ms;
}
.modal-background.fadeOut[data-v-705f785f]{
    animation-delay: 200ms;
    animation-duration:800ms;
}
.modal-content.is-fullwidth[data-v-705f785f]{
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}
