
.card#keyboard-card {
    position: fixed;
    bottom: -300px;
    left: 0;
    width: 100%;
    z-index: 2000;
    transition: bottom 0.5s;
}
.card.is-active#keyboard-card {
    bottom: 0px;
}
