
#zoom-in-button[data-v-1f14f644] {
    position: absolute;
    right: 5px;
    bottom: 75px;
}
#zoom-out-button[data-v-1f14f644] {
    position: absolute;
    bottom: 25px;
    right: 5px;
}
